
.select-button {
    font-size: 20px;
    font-weight: bold;
    border-radius: 10px;
    border-color: black;
    background-color: var(--green);
    margin: 5px;
    padding: 0.5em 1em;
}

.select-button:hover {
    background-color: var(--purple);
}

.donate-button {
    font-size: 16px;
    font-weight: bold;
    border-radius: 10px;
    border-color: black;
    background-color: var(--purple);
    margin: 5px;
    padding: 0.5em 1em;
}

.donate-button:hover {
    background-color: var(--green);
}
