
.page-title {
    text-align: center;
    color: var(--green);
}


.grid-service-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}