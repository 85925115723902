
/*--------------------------------------------------- FLEXBOX --------------------------------------------------------------*/

.flexbox {

  display: flex;

  flex-direction: column;

  /* row is de default, maar toch toegevoegd voor leesbaarheid */
  flex-direction: row;

  /* uitlijning van begin tot eind (indien flex-direction = column, is dat in dit geval boven tot onder, verticaal)*/
  justify-content: flex-end;

  /* uitlijning van begin tot eind indien flex-direction = row, is dat in dit geval links naar rechts, horizontaal )*/
  justify-content: space-between;

  /* uitlijning van begin tot eind (indien direction = row, is dat in dit geval links naar rechts, horizontaal )*/
  justify-content: center;

  /* uitlijning van begin tot eind (indien direction = column, is dat in dit geval boven tot onder, verticaal)*/
  justify-content: center;

  /* uitlijning haaks op de flex-richting (indien flex-direction = row, is dat in dit geval boven naar beneden, verticaal).
   Dit zorgt ervoor dat het woordje Edhub op dezelfde hoogte staat als de menu-items. */
  align-items: center;

  /* uitlijning haaks op de flex-richting (indien flex-direction = column, is dat in dit geval links naar rechts, horizontaal) */
  align-items: center;

  /* alle items groeien NIET mee in verhouding tot de overgebleven ruimte */
  flex-grow: 0;

  /* alle items groeien met evenveel fracties van de overgebleven beschikbare ruimte*/
  flex-grow: 1;

  /* alle items worden NIET kleiner dan de basis */
  flex-shrink: 0;

  /* alle items worden evenredig kleiner dan de basis wanneer er geen ruimte is */
  flex-shrink: 1;

  /*Als we geen basis breedte meegeven wordt de container net zo groot als de content die erin zit.
  Dan is er niks te 'verspreiden' omdat er geen ongebruikte lege ruimte is.
  Zorg daarom altijd dat de container groter is dan de content, als je dit wil bereiken.  */
  flex-basis: 300px;

  /*Wanneer we geen basis-breedte meegeven, hebben de items ook geen ruimte om zich evenredig te verdelen!*/
  flex-basis: 400px;

  /* als het niet meer past, ga verder op de volgende rij*/
  flex-wrap: wrap;

  font-weight: bold;
  /*Zet TEKST nooit hardcoded als HOOFDLETTERS op de pagina, maar transformeer de letters met CSS*/
  text-transform: uppercase;

  /*Boven en onder 30px + links en rechts 40px */
  padding: 30px 40px;

  /*Zo haal je de bullets van een lijst af*/
  list-style: none;

}


/*Om ervoor te zorgen dat afbeeldingen netjes mee schalen wanneer het scherm breder of smaller wordt,
hebben we een andere aanpak nodig dan bij "normale" HTML-elementen.
Dit doen we door onze afbeelding in een wrapper-element te wikkelen, zoals bijvoorbeeld een <span>:

<span class="image-wrapper">
		<img src="..." alt="..."/>
</span>*/


/*Vervolgens geven we de afbeelding altijd een width: 100%, zodat ze altijd 100% van de beschikbare ruimte zullen innemen.
Het wrapper-element krijgt vervolgens een maximale breedte mee (doormiddel van max-width of flex-basis),
waardoor de afbeelding zich telkens zal aanpassen aan het omwikkelende element:*/

/*img {
    width: 100%;
}

.image-wrapper {
    flex-basis: 800px;
    flex-grow: 0;
    flex-shrink: 1;
}*/


/*Maak correct gebruik van de submit-button, inputs, labels en name-attributen. Doe dit vóór je begint met styling;

Blijf weg van absolute breedtes wanneer je invoervelden gaat stylen. Gebruik altijd een width: 100% en laat het formulier
(of een ander omwikkelend element) bepalen hoe breed de velden moeten zijn.
Tip: je kunt placeholders stylen doormiddel van de ::placeholder-selector!*/


/*---------------------------------------------------HTML--------------------------------------------------------------*/


/*Gebruik een afbeelding als achtergrond-afbeelding, doormiddel van de background-image-property.
Vervolgens kun je de background-size en de background-position-property gebruiken om de afbeelding netjes de header te laten bedekken.*/

/*Voor je aan de slag kunt met alle content in het <main>-gedeelte van de webpagina,
is het belangrijk om het main element eerst even goed in te stellen, zodat alle content op dezelfde afstand van de rand komt te staan.
Geef het <main>-element een maximale breedte van 1000px en plaats hem in het midden met de juiste margin.*/

/*Tip: afbeelding te groot? Zet 'm op 100%, dan wordt hij niet groter dan het element waar hij in staat.*/

/*Het speciale <details>-element regelt het in- en uitklappen van tekst.*/
/*De muis kan veranderen in een pointer, wanneer de gebruiker er overeen hovert.*/
/*Om een afbeelding en de naam naast elkaar te krijgen in plaats van onder elkaar,
zul je iets moeten doen met de display-property van de naam.*/
/*Tip: wil je ze netjes uitlijnen met de tekst? Spiek dan eens bij de vertical-align-property!*/


/*Kijk goed naar de inhoud van de tekst om te ontdekken welke bijzonderheden daarin staan.
Denk bijvoorbeeld aan het gebruik van <strong>, <em>, <time>, <cite>, <blockquote>, <code>, <samp> en <sub>.*/

/*Zorg er ook voor dat een video het plaatje placeholder_video.png weergeeft als de video niet wordt afgespeeld.
Dit noem je een thumbnail.*/
/*Houdt er bij het inrichten van een tabel rekening mee dat je o.a. een <thead> en <tbody> gebruikt*/

/*Als de gebruiker op de titels zou kunnen klikken en direct naar dat gedeelte van de pagina geleid wordt,
kunnen we dit doen met behulp van jumplinks: door een titel te voorzien van een id-attribuut, kunnen we daarnaartoe linken.*/


/*---------------------------------------------------CSS BORDER COLORS--------------------------------------------------------------*/


/*body {*/
/*  border: 4px solid #96ec15;*/
/*}*/

/*header,*/
/*footer{*/
/*  border: 4px solid #15ecec;*/
/*}*/

/*nav {*/
/*  border: 4px solid darkorchid;*/
/*}*/

/*main {*/
/*  border: 4px solid #0b0a0a;*/
/*}*/

/*ul {*/
/*  border: 4px solid #0059ff;*/
/*}*/

/*li {*/
/*  border: 4px solid orange;*/
/*}*/

/*div {*/
/*  border: 4px solid #e76455;*/
/*}*/

/*article {*/
/*  border: 4px solid darkred;*/
/*}*/

/*p {*/
/*  border: 4px solid darkcyan;*/
/*}*/

/*h6 {*/
/*  border: 4px solid darkslateblue;*/
/*}*/

/*form {*/
/*  border: 4px solid hotpink;*/
/*}*/

/*img {*/
/*  border: 4px solid yellow;*/
/*}*/

/*.image-wrapper {*/
/*  border: 4px solid #fb9468;*/
/*}*/

/*section {*/
/*  border: 4px solid #444040;*/
/*}*/


@import url('https://fonts.googleapis.com/css2?family=Merriweather&family=Roboto:wght@300;400;700&display=swap');


/*---------------------------------------------------TABLE OF CONTENTS--------------------------------------------------------------*/

/*
        GLOBAL STYLES
        TYPOGRAPHY
        LAYOUT
        REUSABLES
        AREAS
        HEADER
        MAIN
        FOOTER
        OTHER
        MEDIA QUERIES
 */


/* ------------------------------- GLOBAL STYLES -------------------------------- */

:root {
  --background-black: #282c34;
  --grey: #808080FF;
  --white: #ffffff;
  --purple: #60387CFF;
  --green: #4d9f8c;
}


* {
  margin: 0;
  padding: 0;
  /*Dit is belangrijk zodat de breedtes en hoogtes van elementen worden berekend INCLUSIEF padding en borders */
  box-sizing: border-box;
}


body {
  /*font-family: Avenir, Arial, sans-serif;*/
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-size: 20px;
  background-color: var(--background-black);
  color: var(--white);
  text-align: center;

}


/* ------------------------------- TYPOGRAPHY ----------------------------------- */

h1, h2, h3, h4, h5, h6 {
  font-family: Merriweather, 'Times New Roman', serif;
}

h1, h2 {
  font-size: 46px;
  padding: 16px 0;
}

h3 {
  font-size: 36px;
}

h4 {
  font-size: 32px;
  padding: 12px 0;
  margin: 24px 0;
}

h5 {
  font-size: 32px;
  padding: 12px 0;
}

h6 {
  font-size: 32px;
  line-height: 1.5;
  padding: 12px 0;
}

p {
  margin: 12px 0;
}

/* ------------------------------- LAYOUT --------------------------------------- */


.starting-slogan {
  color: var(--green);
}


.fb-like {
  background-color: var(--grey);
}


.top-navigation {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: var(--purple);
  padding: 10px 0;
  margin: 0 20px;
}

.top-navigation-icon:hover {
  border-radius: 50%;
  background-color: var(--grey);
}

.footer-links {

}

.dashboard-link-footer,
.about-us,
.privacy-policy {
  color: var(--grey);
  font-size: 12px;
}



/* --------------- REUSABLES ----------------------- */

/* De buitenste container is altijd de volledige schermbreedte.
Het is een flexbox container die zijn inhoud altijd netjes horizontaal centreert */
.outer-content-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}


/*De binnenste container is maximaal 1400px breed, zodat het er ook goed uitziet op brede schermen.
Hij mag krimpen als het scherm minder breed wordt, maar niet groeien als er meer ruimte is.*/
.inner-content-container {
  flex-basis: 1400px;
  flex-shrink: 1;
  flex-grow: 0;
  /*We voegen margin toe aan de linker- en rechterkant, zodat de content nooit helemaal tegen de rand gedrukt kan worden*/
  margin: 0 20px;
  /*We zorgen er ook voor dat deze flex-item op zijn beurt ook weer een flexbox container is,
  waarin alle children onder elkaar komen te staan*/
  display: flex;
  flex-direction: column;
  align-items: center;
}


/*We gebruiken hier geen flex-basis property, omdat dit element soms in een parent met column-richting en soms in een
parent met row-richting kan staan.
In een column gaat flex-basis over de hoogte, niet de breedte.
Dus om dan alsnog de breedte te veranderen, hebben we max-width (nooit groter dan dit) property nodig */
.default-text-restrictor {
  max-width: 850px;
  text-align: center;
}

.default-area-padding {
  padding: 20px 0;
}


img {
  max-width: 100%
}


.image-wrapper-rotating {
  flex-grow: 0;
  flex-shrink: 1;
}


/* ------------------------------- AREAS ---------------------------------------- */


/* --------------- HEADER ------------------------- */


#header {


}


/* --------------- MAIN ------------------------- */

/*Belangrijk om het main element eerst even goed in te stellen, zodat alle content op dezelfde afstand van de rand komt te staan.
Geef het <main>-element een maximale breedte van 1000px en plaats hem in het midden met de juiste margin.*/

main {
  /*display: flex;*/
  /*justify-content: center;*/
  /*!*font-size: 16px;*!*/
  /*margin: 10px;*/
  /*max-width: 1000px;*/
}


/* --------------- FOOTER ------------------------- */

#footer {
  color: var(--grey);
  text-align: center;
  padding: 20px 0;
  font-size: 12px;
}


/* ------------------------------- OTHER -------------------------------- */

/* ----------------------------------------- FORMS -------------------------------- */

/*.form-container {*/
/*  max-width: 400px;*/
/*  flex-shrink: 1;*/
/*  flex-grow: 0;*/
/*  border-color: white;*/
/*}*/


/*.form {*/
/*  display: flex;*/
/*  flex-direction: row;*/
/*  justify-content: center;*/
/*  !*align-items: center;*!*/
/*  !*text-align: left;*!*/
/*  border-color: var(--white);*/
/*  !*border-radius: 30px;*!*/
/*  !*flex-basis: 1400px;*!*/
/*  flex-shrink: 1;*/
/*  flex-grow: 0;*/
/*  !*margin: 0 20px;*!*/
/*  width: 100%;*/
/*  flex-wrap: wrap;*/
/*}*/


/*------- ROTATING LOGO SMALL ----------------*/


.App-logo-small {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo-small {
    animation: App-logo-small-spin infinite 20s linear;
  }
}

@keyframes App-logo-small-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* ------------------------------- MEDIA QUERIES -------------------------------- */

/*Normaliter maak je altijd gebruik van min-width queries, waarbij de versie voor mobiel BINNEN de query staat, */
/*en de versie voor desktop juist BUITEN de query*/



















